<template>
  <form-dialog title="修改大屏" :formItems="formItems" :formData="formData" @close="$emit('close')" @confirm="confirm">
  </form-dialog>
</template>

<script>
import { ApiSpace } from "@/api/space";

export default {
  name: "UpdateScreenDialog",
  props: {
    detail: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      formItems: [
        {
          label: "大屏ip地址",
          prop: "ip",
          type: "input"
        },
        {
          label: "大屏mac地址",
          prop: "screenMac",
          type: "input"
        }
      ],
      formData: {
        ip: "",
        screenMac: ""
      }
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      if (!this.detail) return;
      const { ip, screenMac } = this.detail;
      this.formData = {
        ip,
        screenMac
      };
    },
    confirm() {
      const { id } = this.detail;
      const params = { ...this.formData, familyId: id };
      ApiSpace.updateScreen(params).then(() => {
        this.$message.success("修改成功");
        this.$emit("save-success");
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
