<template>
  <div class="space-list">
    <building-tree :level="2" @node-click="selectBuilding" />
    <base-list-page
      :searchModel="searchModel"
      :tableModel="tableModel"
      :paginationModel.sync="paginationModel"
      @change="refreshTableData"
    >
      <el-table-column slot="fixedButtons" fixed="right" label="操作" width="100" align="center">
        <template slot-scope="scope">
          <table-button type="primary" @click="detail(scope.row)">详情</table-button>
        </template>
      </el-table-column>
    </base-list-page>
  </div>
</template>

<script>
import { basePaginationProperty } from "@/components/basePagination/basePaginationProperty";
import { searchFormProperty, searchFormItemProperty } from "@/components/form/searchFormProperty";
import { baseTableProperty, colProperty } from "@/components/baseTable/baseTableProperty";
import { VariableType } from "@/components/baseInput/baseInputProperty";
import { ApiSpace } from "@/api/space";
import { ApiBuildingSetting } from "@/api/building";

export default {
  name: "SpaceList",
  data() {
    return {
      searchModel: null,
      tableModel: null,
      paginationModel: new basePaginationProperty(),
      realestateId: null,
      projectId: null
    };
  },
  created() {
    this.initSearch();
    this.initTableList();
  },
  methods: {
    initSearch() {
      let searchModel = searchFormProperty.Init();
      let items = [searchFormItemProperty.Init("楼栋号", "buildingCode", VariableType.SELECT)];
      searchModel.setItems(items);
      searchModel.showReset = true;
      this.searchModel = searchModel;
    },
    initTableList() {
      let model = new baseTableProperty();
      model.colProperty = [
        colProperty.Init("楼栋号", "buildingCode"),
        colProperty.Init("共有单元数", "unitCount"),
        colProperty.Init("楼层数", "floorCount"),
        colProperty.Init("共有户数", "familyCount"),
        colProperty.Init("已绑定户数", "bindMacCount"),
        colProperty.Init("未绑定户数", "unBindMacCount")
      ];
      this.tableModel = model;
    },
    initOptions() {
      if (!this.projectId) return;
      ApiBuildingSetting.getBuilds(this.projectId).then((res) => {
        this.searchModel.setOptions("buildingCode", res);
      });
    },
    refreshTableData() {
      let params = {
        pageNum: this.paginationModel.currentPage,
        pageSize: this.paginationModel.pageSize,
        realestateId: this.realestateId,
        projectId: this.projectId,
        ...this.searchModel.getItemsValue()
      };
      ApiSpace.list(params).then((res) => {
        this.tableModel.setData(res.list);
        this.paginationModel.total = res.total;
      });
    },
    // 选择楼盘
    selectBuilding(value) {
      this.realestateId = value[0];
      this.projectId = value[1];
      this.paginationModel.currentPage = 1;
      this.initOptions();
      this.refreshTableData();
    },
    detail({ projectId, buildingCode }) {
      this.$router.push({
        path: "/space/building/detail",
        query: {
          projectId,
          buildingCode,
          realestateId: this.realestateId
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.space-list {
  display: flex;
  height: 100%;
  ::v-deep .detail-info {
    flex: 1;
  }
}
</style>
