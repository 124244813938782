<template>
  <form-dialog
    title="维护大屏"
    :formItems="formItems"
    :formData="formData"
    confirmLabel="远程"
    @close="$emit('close')"
    @confirm="confirm"
  >
  </form-dialog>
</template>

<script>
import { ApiSpace } from "@/api/space";

export default {
  name: "MaintainScreenDialog",
  props: {
    detail: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      formItems: [
        {
          label: "Server Url",
          prop: "serverUrl",
          type: "input",
          rules: [{ required: true, message: "请输入", trigger: "blur" }]
        },
        {
          label: "period (min)",
          prop: "period",
          type: "input",
          rules: [{ required: true, message: "请输入", trigger: "blur" }]
        }
      ],
      formData: {
        serverUrl: "ngrok.landleaf-tech.com",
        period: "15"
      }
    };
  },
  methods: {
    confirm() {
      const { id } = this.detail;
      const params = { ...this.formData, familyId: id };
      ApiSpace.maintainScreen(params).then(() => {
        this.$message.success("推送成功");
        this.$emit("success");
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
