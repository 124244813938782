<!--
 * @Description: 设备基本信息
 * @Author: zhongyuanrui
 * @LastEditors: zhongyuanrui
 * @FilePath: \homeauto-web-v3.0\src\views\space\DeviceBaseInfo.vue
-->
<template>
  <div>
    <base-card title="设备基本信息" class="device-base-info">
      <el-form ref="form" label-width="120px" label-position="left">
        <el-col :span="12" v-for="item in formItems" :key="item.prop">
          <el-form-item :label="item.label" :prop="item.prop">{{ item.value }}</el-form-item>
        </el-col>
      </el-form>
    </base-card>
  </div>
</template>

<script>
import { ApiFamilyDevice } from "@/api/space";
export default {
  name: "DeviceBaseInfo",
  data() {
    return {
      formItems: [
        {
          label: "设备编码：",
          prop: "deviceSn",
          value: ""
        },
        {
          label: "设备名称：",
          prop: "deviceName",
          value: ""
        },
        {
          label: "设备所在房屋：",
          prop: "familyName",
          value: ""
        },
        {
          label: "所属系统产品：",
          prop: "sysProductName",
          value: ""
        },
        {
          label: "设备所属产品：",
          prop: "productName",
          value: ""
        },
        {
          label: "产品品类：",
          prop: "categoryName",
          value: ""
        },
        {
          label: "设备品牌：",
          prop: "brandName",
          value: ""
        },
        {
          label: "产品型号：",
          prop: "model",
          value: ""
        },
        {
          label: "设备位置：",
          prop: "roomName",
          value: ""
        },
        {
          label: "设备状态：",
          prop: "onlineFlagStr",
          value: ""
        }
      ]
    };
  },
  created() {
    this.initData();
    this.initBreadcrumbs();
  },
  methods: {
    initBreadcrumbs() {
      let query = this.$route.query;
      let breadcrumbs = JSON.parse(query.breadcrumbs);
      breadcrumbs.push({
        title: "设备基本信息"
      });
      this.breadcrumbs = breadcrumbs;
      this.$store.commit("SET_BREADCRUMBS", breadcrumbs);
    },
    initData() {
      const { deviceId, familyId } = this.$route.query;
      if (!deviceId || !familyId) return;
      let params = {
        deviceId,
        familyId
      };
      ApiFamilyDevice.detail(params).then((res) => {
        this.formItems.forEach((item) => {
          item.value = res[item.prop];
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.device-base-info-handle-bar {
  margin-right: 20px;
}
</style>
