<!--
 * @Description: 房屋-设备详情
 * @Author: zhongyuanrui
 * @LastEditors: zhongyuanrui
 * @FilePath: \homeauto-web-v3.0\src\views\space\BuildingDeviceDetail.vue
-->
<template>
  <div>
    <base-list-page
      :searchModel="searchModel"
      :tableModel="tableModel"
      :paginationModel.sync="paginationModel"
      @change="refreshTableData"
    >
      <el-table-column slot="fixedButtons" fixed="right" label="操作" width="100" align="center">
        <template slot-scope="scope">
          <table-button type="primary" @click="detail(scope.row)">详情</table-button>
        </template>
      </el-table-column>
    </base-list-page>
  </div>
</template>

<script>
import { basePaginationProperty } from "@/components/basePagination/basePaginationProperty";
import { searchFormProperty, searchFormItemProperty } from "@/components/form/searchFormProperty";
import { baseTableProperty, colProperty } from "@/components/baseTable/baseTableProperty";
import { ApiFamilyDevice } from "@/api/space";

export default {
  name: "BuildingDeviceDetail",
  data() {
    return {
      ...this.$route.query,
      searchModel: null,
      tableModel: null,
      paginationModel: new basePaginationProperty()
    };
  },
  created() {
    this.initSearch();
    this.initTableList();
  },
  mounted() {
    let q = this.$route.query;
    let breadcrumbs = [
      { path: "/space/list", title: "空间管理" },
      {
        title: "房屋详情",
        path: `/space/building/detail?realestateId=${q.realestateId}&projectId=${q.projectId}&buildingCode=${q.buildingCode}`
      },
      {
        title: "设备详情",
        path: this.$route.fullPath
      }
    ];
    this.breadcrumbs = breadcrumbs;
    this.$store.commit("SET_BREADCRUMBS", breadcrumbs);
  },
  methods: {
    initSearch() {
      let searchModel = searchFormProperty.Init();
      let items = [searchFormItemProperty.Init("设备名称", "deviceName")];
      searchModel.setItems(items);
      searchModel.showReset = true;
      this.searchModel = searchModel;
    },
    initTableList() {
      let model = new baseTableProperty();
      model.colProperty = [
        colProperty.Init("房屋编号", "familyCode"),
        colProperty.Init("房屋名称", "familyName"),
        colProperty.Init("设备名称", "deviceName"),
        colProperty.Init("所属系统产品", "sysProductName"),
        colProperty.Init("设备位置", "roomName"),
        colProperty.Init("状态", "onlineFlagStr")
      ];
      this.tableModel = model;
      this.refreshTableData();
    },
    refreshTableData() {
      this.tableModel.setData([{ id: 1 }, { id: 2 }]);
      let params = {
        pageNum: this.paginationModel.currentPage,
        pageSize: this.paginationModel.pageSize,
        realestateId: this.realestateId,
        projectId: this.projectId,
        buildingCode: this.buildingCode,
        familyId: this.$route.query.familyId,
        ...this.searchModel.getItemsValue()
      };
      ApiFamilyDevice.list(params).then((res) => {
        this.tableModel.setData(res.list);
        this.paginationModel.total = res.total;
      });
    },
    detail(row) {
      const { deviceId, familyId } = row;
      if (!deviceId || !familyId) return;
      this.$router.push({
        path: "/space/device/base/info",
        query: {
          deviceId,
          familyId,
          breadcrumbs: JSON.stringify(this.breadcrumbs)
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
