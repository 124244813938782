<template>
  <div>
    <base-list-page
      :searchModel="searchModel"
      :tableModel="tableModel"
      :paginationModel.sync="paginationModel"
      @change="refreshTableData"
    >
      <template v-slot:button>
        <!-- <upload-button class="btn" label="批量导入大屏地址" @upload-success="uploadSuccess" />
        <el-button class="btn" type="primary" size="small" @click="download">模板下载</el-button> -->
      </template>
      <el-table-column slot="fixedButtons" fixed="right" label="操作" width="240" align="center">
        <template slot-scope="scope">
          <table-button v-if="scope.row.bindStatusStr === '已绑定'" type="primary" @click="detail(scope.row)"
            >详情</table-button
          >
          <table-button @click="updateScreen(scope.row)">修改大屏</table-button>
          <table-button @click="maintainScreen(scope.row)">维护大屏</table-button>
          <table-button :loading="downloadLoading" @click="downloadConfig(scope.row)">配置导出</table-button>
        </template>
      </el-table-column>
    </base-list-page>
    <update-screen-dialog
      v-if="updateScreenDialogVisible"
      :detail="currentRow"
      @close="updateScreenDialogVisible = false"
      @save-success="saveSuccess"
    />
    <maintain-screen-dialog
      v-if="maintainScreenDialogVisible"
      :detail="currentRow"
      @close="maintainScreenDialogVisible = false"
      @success="maintainSuccess"
    />
  </div>
</template>

<script>
import { basePaginationProperty } from "@/components/basePagination/basePaginationProperty";
import { searchFormProperty, searchFormItemProperty } from "@/components/form/searchFormProperty";
import { baseTableProperty, colProperty } from "@/components/baseTable/baseTableProperty";
import { VariableType } from "@/components/baseInput/baseInputProperty";
import { ApiBuildingSettingDetail } from "@/api/building";
import UpdateScreenDialog from "./components/UpdateScreenDialog";
import MaintainScreenDialog from "./components/MaintainScreenDialog";
import { downLoad } from "@/utils/util";

export default {
  name: "BuildingDetail",
  components: { UpdateScreenDialog, MaintainScreenDialog },
  data() {
    return {
      ...this.$route.query,
      searchModel: null,
      tableModel: null,
      paginationModel: new basePaginationProperty(),
      updateScreenDialogVisible: false,
      maintainScreenDialogVisible: false,
      currentRow: null,
      downloadLoading: false
    };
  },
  created() {
    this.initSearch();
    this.initOptions();
    this.initTableList();
  },
  methods: {
    initSearch() {
      let searchModel = searchFormProperty.Init();
      let items = [
        searchFormItemProperty.Init("所在单元", "unitCode", VariableType.SELECT),
        searchFormItemProperty.Init("所在楼层", "floor", VariableType.SELECT),
        searchFormItemProperty.Init("门牌号", "doorplate"),
        searchFormItemProperty.Init("绑定状态", "bindStatus", VariableType.SELECT),
        searchFormItemProperty.Init("大屏IP地址", "ip"),
        searchFormItemProperty.Init("大屏mac地址", "screenMac")
      ];
      searchModel.setItems(items);
      searchModel.showReset = true;
      this.searchModel = searchModel;
    },
    initOptions() {
      const params = {
        buildCode: this.buildingCode,
        projectId: this.projectId
      };
      ApiBuildingSettingDetail.unitOptions(params).then((res) => {
        this.searchModel.setOptions("unitCode", res);
      });
      ApiBuildingSettingDetail.floorOptions(params).then((res) => {
        this.searchModel.setOptions("floor", res);
      });
      const list = [
        {
          value: 1,
          label: "已绑定"
        },
        {
          value: 0,
          label: "未绑定"
        }
      ];
      this.searchModel.setOptions("bindStatus", list);
    },
    initTableList() {
      let model = new baseTableProperty();
      model.colProperty = [
        colProperty.Init("房屋名称", "name"),
        colProperty.Init("所在楼栋", "buildingCodeStr", 100),
        colProperty.Init("所在单元", "unitCodeStr", 100),
        colProperty.Init("所属楼层", "floorName", 100),
        colProperty.Init("门牌号", "doorplate", 100),
        colProperty.Init("绑定状态", "bindStatusStr", 100),
        colProperty.Init("户型", "templateName", 100),
        colProperty.Init("大屏ip地址", "ip"),
        colProperty.Init("大屏mac地址", "screenMac")
      ];
      this.tableModel = model;
      this.refreshTableData();
    },
    refreshTableData() {
      let params = {
        projectId: this.projectId,
        buildingCode: this.buildingCode,
        pageNum: this.paginationModel.currentPage,
        pageSize: this.paginationModel.pageSize,
        ...this.searchModel.getItemsValue()
      };
      ApiBuildingSettingDetail.list(params).then((res) => {
        this.tableModel.setData(res.list);
        this.paginationModel.total = res.total;
      });
    },
    detail({ buildingCode, id }) {
      this.$router.push({
        path: "/space/building/device/detail",
        query: {
          realestateId: this.realestateId,
          projectId: this.projectId,
          buildingCode,
          familyId: id
        }
      });
    },
    updateScreen(row) {
      this.currentRow = row;
      this.updateScreenDialogVisible = true;
    },
    saveSuccess() {
      this.updateScreenDialogVisible = false;
      this.refreshTableData();
    },
    maintainScreen(row) {
      this.currentRow = row;
      this.maintainScreenDialogVisible = true;
    },
    maintainSuccess() {
      this.maintainScreenDialogVisible = false;
      this.refreshTableData();
    },
    uploadSuccess() {},
    download() {},
    // 配置导出
    async downloadConfig({ id }) {
      this.downloadLoading = true;
      try {
        const { filename, blob } = await ApiBuildingSettingDetail.downloadConfig(id);
        downLoad(blob, decodeURI(filename));
      } catch (error) {
        console.log(error);
      }
      this.downloadLoading = false;
    }
  }
};
</script>

<style lang="scss" scoped></style>
